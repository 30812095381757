import http from "@/utils/http";

export default {
  bill: {
    calc: (data) => {
      return http({
        url: "/api/v1.PriceCalc/calc",
        data,
        op: false,
      }).then((res) => res);
    },
    findAcceptCompany: (data) => {
      return http({
        url: "/api/v2.order/findAcceptCompany",
        data,
        op: false,
      }).then((res) => res);
    },
    custom: (data) => {
      return http({
        url: "/api/v1.custom/getList",
        data,
        op: false,
      }).then((res) => res);
    },
    create: (data) => {
      return http({
        url: "/api/v1.order/create",
        data,
        op: false,
      }).then((res) => res);
    },
    detail: (data) => {
      return http({
        url: "/api/v1.order/detail",
        data,
        op: false,
      }).then((res) => res);
    },
    cancel: (data) => {
      return http({
        url: "/api/v1.order/cancel",
        data,
      }).then((res) => res);
    },
    statusList: (data) => {
      return http({
        url: "/api/v1.order/statusList",
        data,
        op: false,
      }).then((res) => res);
    },
    orderSearch: (data) => {
      return http({
        url: "/api/v1.order/orderSearch",
        data,
        op: false,
      }).then((res) => res);
    },
    ProjectOrder: (data) => {
      return http({
        url: "/api/v2.ProjectOrder/detail",
        data,
        op: false,
      }).then((res) => res);
    },
    V2create: (data) => {
      return http({
        url: "/api/v2.order/create",
        data,
        op: false,
      }).then((res) => res);
    },
  },
};
