import http from "@/utils/http";

export default {
  area: (data) => {
    return http({
      url: "/api/v1/admin/area/list",
      data,
      op: false,
    }).then((res) => res);
  },
  areas: (data) => {
    return http({
      url: "/api/v1.basic/areaLists",
      data,
      op: false,
    }).then((res) => res);
  },
  city: (data) => {
    return http({
      url: "/api/v1.basic/areaListByCity",
      data,
      op: false,
    }).then((res) => res);
  },
  export: (data) => {
    return http({
      url: "/api/v1.order/export",
      data,
    }).then((res) => res);
  },
  orderSearchExport: (data) => {
    return http({
      url: "/api/v1.order/orderSearchExport",
      data,
    }).then((res) => res);
  },
  uploadImage: (data) => {
    return http({
      url: "/api/v1.basic/uploadImage",
      data,
      op: false,
    }).then((res) => res);
  },
  exportExcel: (data) => {
    return http({
      url: "/api/v1.Basic/exportExcel",
      data,
      op: false,
    }).then((res) => res);
  },
};
