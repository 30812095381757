import http from "@/utils/http";

export default {
  //角色接口
  roles: {
    add: (data) => {
      return http({
        url: "/api/v1.roles/create",
        data,
      }).then((res) => res);
    },
    del: (data) => {
      return http({
        url: "/api/v1.roles/delete",
        data,
      }).then((res) => res);
    },
    update: (data) => {
      return http({
        url: "/api/v1.roles/update",
        data,
      }).then((res) => res);
    },
    list: (data) => {
      return http({
        url: "/api/v1.roles/list",
        data,
        op: false,
      }).then((res) => res);
    },
    selectList: (data) => {
      return http({
        url: "/api/v1.roles/selectList",
        data,
        op: false,
      }).then((res) => res);
    },
    menuListWithLevel: (data) => {
      return http({
        url: "/api/v1/admin/roles/menuListWithLevel",
        data,
        op: false,
      }).then((res) => res);
    },
  },
  //部门接口
  dept: {
    add: (data) => {
      return http({
        url: "/api/v1.dept/create",
        data,
      }).then((res) => res);
    },
    edit: (data) => {
      return http({
        url: "/api/v1.dept/update",
        data,
      }).then((res) => res);
    },
    list: (data) => {
      return http({
        url: "/api/v1.dept/list",
        data,
        op: false,
      }).then((res) => res);
    },
    del: (data) => {
      return http({
        url: "/api/v1.dept/delete",
        data,
      }).then((res) => res);
    },
    getDeptByCenter: (data) => {
      return http({
        url: "/api/v1.dept/getDeptByCenter",
        data,
        op: false,
      }).then((res) => res);
    },
    getDeptBySite: (data) => {
      return http({
        url: "/api/v1.dept/getDeptBySite",
        data,
        op: false,
      }).then((res) => res);
    },
    listByStation: (data) => {
      return http({
        url: "/api/v1.dept/listByStation",
        data,
        op: false,
      }).then((res) => res);
    },
    listByType: (data) => {
      return http({
        url: "/web/v1.dept/listByType",
        data,
        op: false,
      }).then((res) => res);
    },
  },
  //员工接口
  user: {
    add: (data) => {
      return http({
        url: "/api/v1.user/create",
        data,
      }).then((res) => res);
    },
    update: (data) => {
      return http({
        url: "/api/v1.user/update",
        data,
      }).then((res) => res);
    },
    list: (data) => {
      return http({
        url: "/api/v1.user/list",
        data,
        op: false,
      }).then((res) => res);
    },
    del: (data) => {
      return http({
        url: "/api/v1.user/delete",
        data,
      }).then((res) => res);
    },
  },
  //菜单接口
  menu: {
    add: (data) => {
      return http({
        url: "/api/v1.menu/create",
        data,
      }).then((res) => res);
    },
    update: (data) => {
      return http({
        url: "/api/v1.menu/update",
        data,
      }).then((res) => res);
    },
    list: (data) => {
      return http({
        url: "/api/v1.menu/list",
        data,
        op: false,
      }).then((res) => res);
    },
    alllist: (data) => {
      return http({
        url: "/api/v1.menu/all-list",
        data,
        op: false,
      }).then((res) => res);
    },
    del: (data) => {
      return http({
        url: "/api/v1.menu/delete",
        data,
      }).then((res) => res);
    },
  },
  price_card: {
    edit: (data) => {
      return http({
        url: "/api/v1/admin/price-card/edit",
        data,
      }).then((res) => res);
    },
    list: (data) => {
      return http({
        url: "/api/v1/admin/price-card/list",
        data,
        op: false,
      }).then((res) => res);
    },
  },
  rate: {
    edit: (data) => {
      return http({
        url: "/api/v1/admin/rate/edit",
        data,
      }).then((res) => res);
    },
    list: (data) => {
      return http({
        url: "/api/v1/admin/rate/list",
        data,
        op: false,
      }).then((res) => res);
    },
  },
  carry: {
    edit: (data) => {
      return http({
        url: "/api/v1/admin/carry-rule/edit",
        data,
      }).then((res) => res);
    },
    add: (data) => {
      return http({
        url: "/api/v1/admin/carry-rule/add",
        data,
      }).then((res) => res);
    },
    list: (data) => {
      return http({
        url: "/api/v1/admin/carry-rule/list",
        data,
        op: false,
      }).then((res) => res);
    },
  },
  app: {
    create: (data) => {
      return http({
        url: "/api/v1/admin/version-manager/create",
        data,
      }).then((res) => res);
    },
    delete: (data) => {
      return http({
        url: "/api/v1/admin/version-manager/delete",
        data,
      }).then((res) => res);
    },
    list: (data) => {
      return http({
        url: "/api/v1/admin/version-manager/list",
        data,
        op: false,
      }).then((res) => res);
    },
    version: (data) => {
      return http({
        url: "/api/v1/admin/common/version",
        data,
        op: false,
      }).then((res) => res);
    },
  },
  rail: {
    list: (data) => {
      return http({
        url: "/api/v1/admin/company/geofence-list",
        data,
        op: false,
      }).then((res) => res);
    },
    create: (data) => {
      return http({
        url: "/api/v1/admin/geofence/create",
        data,
      }).then((res) => res);
    },
    edit: (data) => {
      return http({
        url: "/api/v1/admin/geofence/edit",
        data,
      }).then((res) => res);
    },
    del: (data) => {
      return http({
        url: "/api/v1/admin/geofence/delete",
        data,
      }).then((res) => res);
    },
    price_create: (data) => {
      return http({
        url: "/api/v1/admin/geofence-price/create",
        data,
      }).then((res) => res);
    },
    price_list: (data) => {
      return http({
        url: "/api/v1/admin/geofence-price/list",
        data,
        op: false,
      }).then((res) => res);
    },
  },
};
