<template>
  <template>
    <a-drawer
      :footer="option.footer ?? true"
      :height="option.height"
      :mask="true"
      :mask-closable="true"
      :title="option.title"
      :visible="option.show"
      :width="option.width"
      placement="right"
      @cancel="handleClick('close')"
      @ok="handleClick('confirm')"
    >
      <slot></slot>
      <template #header>
        <slot name="header"></slot>
      </template>
      <template #footer>
        <a-button @click="handleClick('cancel')">取消</a-button>
        <a-button
          :loading="option.loading ?? false"
          type="primary"
          @click="handleClick('confirm')"
          >确定
        </a-button>
      </template>
    </a-drawer>
  </template>
</template>
// js方面，没啥可讲的
<script>
export default {
  name: "x-drawer",
  props: {
    option: {
      type: Object,
      default: () => {
        return {
          show: false,
          title: "弹窗标题",
          loading: false,
          footer: true,
          width: "1000px",
          height: "1000px",
          top: "",
          okText: "确定",
          cancelText: "取消",
        };
      },
    },
  },
  methods: {
    handleClick(val) {
      if (val === "confirm") {
        this.$emit("tap", val);
      } else {
        if (!this.option.loading) {
          this.$emit("close");
        }
      }
    },
  },
};
</script>
<style lang="less">
.arco-drawer {
  top: 70px;
  height: calc(100% - 70px);
}
</style>
