import http from "@/utils/http";

export default {
  list: (data) => {
    return http({
      url: "/api/v1.Transition/list",
      data,
      op: false,
    }).then((res) => res);
  },
  sendList: (data) => {
    return http({
      url: "/api/v1.Transition/sendList",
      data,
      op: false,
    }).then((res) => res);
  },
  endList: (data) => {
    return http({
      url: "/api/v1.Transition/endList",
      data,
      op: false,
    }).then((res) => res);
  },
  minList: (data) => {
    return http({
      url: "/api/v1.Transition/minList",
      data,
      op: false,
    }).then((res) => res);
  },
  deliveryList: (data) => {
    return http({
      url: "/api/v1.Transition/deliveryList",
      data,
      op: false,
    }).then((res) => res);
  },
  send: (data) => {
    return http({
      url: "/api/v1.Transition/send",
      data,
    }).then((res) => res);
  },
  put: (data) => {
    return http({
      url: "/api/v1.Transition/transPut",
      data,
    }).then((res) => res);
  },
  site: (data) => {
    return http({
      url: "/api/v1.Transition/transSite",
      data,
    }).then((res) => res);
  },
  out: (data) => {
    return http({
      url: "/api/v1.Transition/transOut",
      data,
    }).then((res) => res);
  },
  deliveryPut: (data) => {
    return http({
      url: "/api/v1.Transition/deliveryPut",
      data,
    }).then((res) => res);
  },
  delivery: (data) => {
    return http({
      url: "/api/v1.Transition/delivery",
      data,
    }).then((res) => res);
  },
  sign: (data) => {
    return http({
      url: "/api/v1.Transition/sign",
      data,
    }).then((res) => res);
  },
  minBackInputOrder: (data) => {
    return http({
      url: "/api/v1.Transition/minBackInputOrder",
      data,
    }).then((res) => res);
  },
};
