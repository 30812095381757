import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";
import Layout from "@/views/layout/Layout";

const routes = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/login/index.vue"),
  },
  {
    path: "/",
    name: "main",
    redirect: "/home",
    component: Layout,
    children: [
      {
        path: "/home",
        name: "home",
        meta: {
          title: "首页",
        },
        component: () => import("@/views/home/index"),
      },
      {
        path: "/user",
        name: "user",
        meta: {
          title: "个人中心",
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/layout/User.vue"),
      },
      {
        path: "/order",
        name: "order",
        meta: {
          title: "运单追踪",
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/layout/Order.vue"),
      },
      // {
      //   path: "/operate/OrderDetails",
      //   name: "OrderDetails",
      //   meta: {
      //     title: "订单详情",
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ "@/components/OrderDetails.vue"
      //     ),
      // },
      {
        path: "/operate/list/bargain",
        name: "bargain",
        meta: {
          title: "议价开单",
          keepAlive: true,
        },
        component: () => import("@/views/operate/list/bargain/index"),
      },
      {
        path: "/operate/list/bill",
        name: "bill",
        meta: {
          title: "零担录单",
          keepAlive: true,
        },
        component: () => import("@/views/operate/list/bill/index"),
      },
      {
        path: "/operate/list/order-list",
        name: "order-list",
        meta: {
          title: "订单列表",
          keepAlive: true,
        },
        component: () => import("@/views/operate/list/order-list/index"),
      },
      {
        path: "/operate/list/sender-list",
        name: "sender-list",
        meta: {
          title: "发件查询",
          keepAlive: true,
        },
        component: () => import("@/views/operate/list/sender-list/index"),
      },
      {
        path: "/operate/list/carriera-list",
        name: "carriera-list",
        meta: {
          title: "承运查询",
          keepAlive: true,
        },
        component: () => import("@/views/operate/list/carriera-list/index"),
      },
      {
        path: "/operate/list/delivery-list",
        name: "delivery-list",
        meta: {
          title: "配送查询",
          keepAlive: true,
        },
        component: () => import("@/views/operate/list/delivery-list/index"),
      },
      {
        path: "/operate/list/control-list",
        name: "control-list",
        meta: {
          title: "控货运单",
          keepAlive: true,
        },
        component: () => import("@/views/operate/list/control-list/index"),
      },
      {
        path: "/operate/reverse/sender-reverse",
        name: "sender-reverse",
        meta: {
          title: "发件操作",
          keepAlive: true,
        },
        component: () => import("@/views/operate/reverse/sender-reverse/index"),
      },
      {
        path: "/operate/reverse/delivery-reverse",
        name: "delivery-reverse",
        meta: {
          title: "到件操作",
          keepAlive: true,
        },
        component: () =>
          import("@/views/operate/reverse/delivery-reverse/index"),
      },
      {
        path: "/operate/reverse/delivery-small",
        name: "delivery-small",
        meta: {
          title: "小件外部中转",
          keepAlive: true,
        },
        component: () => import("@/views/operate/reverse/delivery-small/index"),
      },
      {
        path: "/operate/receipt/send-receipt",
        name: "send-receipt",
        meta: {
          title: "回单发件",
          keepAlive: true,
        },
        component: () => import("@/views/operate/receipt/send-receipt/index"),
      },
      {
        path: "/operate/receipt/turn-receipt",
        name: "turn-receipt",
        meta: {
          title: "回单到件",
          keepAlive: true,
        },
        component: () => import("@/views/operate/receipt/turn-receipt/index"),
      },
      {
        path: "/operate/receipt/recycle-receipt",
        name: "recycle-receipt",
        meta: {
          title: "回单回收",
          keepAlive: true,
        },
        component: () =>
          import("@/views/operate/receipt/recycle-receipt/index"),
      },
      //项目管理
      {
        path: "/operate/project/client",
        name: "client",
        meta: {
          title: "项目客户",
          keepAlive: true,
        },
        component: () => import("@/views/operate/project/client/index"),
      },
      {
        path: "/operate/project/project",
        name: "project",
        meta: {
          title: "项目配置",
          keepAlive: true,
        },
        component: () => import("@/views/operate/project/project/index"),
      },
      {
        path: "/operate/project/bill",
        name: "project-bill",
        meta: {
          title: "项目开单",
          keepAlive: true,
        },
        component: () => import("@/views/operate/project/bill/index"),
      },
      {
        path: "/operate/project/order",
        name: "project-order",
        meta: {
          title: "项目订单",
          keepAlive: true,
        },
        component: () => import("@/views/operate/project/order/index"),
      },
      {
        path: "/operate/project/billlist",
        name: "project-billlist",
        meta: {
          title: "待对账列表",
          keepAlive: true,
        },
        component: () => import("@/views/operate/project/billlist/index"),
      },
      {
        path: "/operate/project/checking",
        name: "checking",
        meta: {
          title: "已对账列表",
          keepAlive: true,
        },
        component: () => import("@/views/operate/project/checking/index"),
      },
      {
        path: "/operate/car/driver-list",
        name: "driver-list",
        meta: {
          title: "司机列表",
          keepAlive: true,
        },
        component: () => import("@/views/operate/car/driver-list/index"),
      },
      //企业管理
      {
        path: "/finance/ewallet/company-account",
        name: " company-account",
        meta: {
          title: "企业账户",
        },
        component: () =>
          import("@/views/finance/ewallet/company-account/index"),
      },
      {
        path: "/finance/ewallet/company-audit",
        name: " company-audit",
        meta: {
          title: "充值审核",
        },
        component: () => import("@/views/finance/ewallet/company-audit/index"),
      },
      {
        path: "/finance/ewallet/withdraw-audit",
        name: " withdraw-audit",
        meta: {
          title: "提现审核",
        },
        component: () => import("@/views/finance/ewallet/withdraw-audit/index"),
      },
      {
        path: "/finance/ewallet/wallet",
        name: "wallet",
        meta: {
          title: "我的钱包",
        },
        component: () => import("@/views/finance/ewallet/wallet/index"),
      },
      {
        path: "/finance/ewallet/difference",
        name: "difference",
        meta: {
          title: "差异审核",
        },
        component: () => import("@/views/finance/ewallet/difference/index"),
      },
      {
        path: "/finance/financing/withdraw-remit",
        name: "withdraw-remit",
        meta: {
          title: "提现打款",
        },
        component: () =>
          import("@/views/finance/financing/withdraw-remit/index"),
      },
      {
        path: "/finance/archivme/receivable",
        name: "receivable",
        meta: {
          title: "应收账单",
        },
        component: () => import("@/views/finance/archivme/receivable/index"),
      },
      {
        path: "/finance/archivme/handle",
        name: "handle",
        meta: {
          title: "应付账单",
        },
        component: () => import("@/views/finance/archivme/handle/index"),
      },
      {
        path: "/service/search/order-search",
        name: "order-search",
        meta: {
          title: "运单追踪",
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/service/search/order-search/index.vue"
          ),
      },
      {
        path: "/set/freight/artery-coefficient",
        name: "artery-coefficient",
        meta: {
          title: "调整系数",
        },
        component: () => import("@/views/set/freight/artery-coefficient/index"),
      },
      {
        path: "/set/freight/artery-price",
        name: "artery-price",
        meta: {
          title: "大票运费方案",
        },
        component: () => import("@/views/set/freight/artery-price/index"),
      },
      {
        path: "/set/freight/small-artery",
        name: "small-artery",
        meta: {
          title: "小票运费方案",
        },
        component: () => import("@/views/set/freight/small-artery/index"),
      },
      {
        path: "/set/freight/delivery-price",
        name: "delivery-price",
        meta: {
          title: "大票送货费方案",
        },
        component: () => import("@/views/set/freight/delivery-price/index"),
      },
      {
        path: "/set/freight/small-delivery",
        name: "small-delivery",
        meta: {
          title: "大票送货费方案",
        },
        component: () => import("@/views/set/freight/small-delivery/index"),
      },
      {
        path: "/set/freight/price-card",
        name: "price-card",
        meta: {
          title: "大小票区分",
        },
        component: () => import("@/views/set/freight/price-card/index"),
      },
      {
        path: "/set/freight/rate-deploy",
        name: "rate-deploy",
        meta: {
          title: "其他费率管理",
        },
        component: () => import("@/views/set/freight/rate-deploy/index"),
      },
      {
        path: "/set/freight/rail",
        name: "rail",
        meta: {
          title: "电子围栏",
        },
        component: () => import("@/views/set/freight/rail/index"),
      },
      {
        path: "/set/enterprise/company",
        name: "company",
        meta: {
          title: "企业列表",
        },
        component: () => import("@/views/set/enterprise/company/index"),
      },
      //基础管理
      {
        path: "/set/organization/roles",
        name: "roles",
        meta: {
          title: "角色权限",
        },
        component: () => import("@/views/set/organization/roles/index"),
      },
      {
        path: "/set/organization/employee",
        name: "employee",
        meta: {
          title: "部门组织",
        },
        component: () => import("@/views/set/organization/employee/index"),
      },
      {
        path: "/set/organization/user-staff",
        name: "user-staff",
        meta: {
          title: "员工列表",
        },
        component: () => import("@/views/set/organization/user-staff/index"),
      },
      {
        path: "/set/organization/menu",
        name: "menu",
        meta: {
          title: "菜单管理",
        },
        component: () => import("@/views/set/organization/menu/index"),
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
// router.beforeEach((to, from, next) => {
// 	loadingBar().start();
// 	next();
// })
router.beforeEach((to, from, next) => {
  //在页面加载时读取sessionStorage里的状态信息
  if (window.sessionStorage.getItem("store")) {
    store.replaceState(
      Object.assign({}, JSON.parse(sessionStorage.getItem("store")))
    );
    window.sessionStorage.removeItem("store");
  }
  //登录检测
  let users = store.getters["base/get_user"];
  //未登录且访问的页面不是登录页，跳转至登录页面
  if (users == null && to.path !== "/login") {
    next({
      path: "/login",
    });
    document.title = to.meta.title ? to.meta.title + "-能到" : "-能到";
  } else if (to.path === "/login") {
    store.dispatch("base/logout").then((r) => r);
    next();
    document.title = to.meta.title ? to.meta.title + "-能到" : "-能到";
  } else {
    next();
    document.title = to.meta.title ? to.meta.title + "-能到" : "-能到";
  }
});
router.afterEach(() => {
  // loadingBar().success();
});
export default router;
