const state = {
  token: null,
  time: null,
};
const mutations = {
  set_token(state, data) {
    state.token = data;
  },
  set_time(state, data) {
    state.time = data;
  },
};
const actions = {
  up_token({ commit }, data) {
    commit("set_token", data);
  },
  up_time({ commit }, data) {
    commit("set_time", data);
  },
};
const getters = {
  get_token(state) {
    return state.token;
  },
  get_time(state) {
    return state.time;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
