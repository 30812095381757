import axios from "axios";
import store from "../store/index.js";
import router from "../router/index.js";
import {
    baseUrl
} from "../utils/config";
import {
    Notification
} from '@arco-design/web-vue';

const http = ({
                  url,
                  data = "",
                  op = true
              }) => {
    // //axios 拦截器
    // axios.interceptors.request.use(
    //     function (config) {
    //         let user = store.getters["base/get_user"];
    //         if (user != null) {
    //             config.headers.authorization = user.token;
    //         }
    //         return config;
    //     },
    //     function (err) {
    //         return Promise.reject(err);
    //     }
    // );
    let user = store.getters["base/get_user"];
    return new Promise((resolve, reject) => {
        axios.post(baseUrl + url, data,{
            headers:{
                'authorization':user != null ? user.token :''
            }
        }).then((res) => {
            if (res.data.code === 502) {
                router.push({
                    path: "/login",
                });
            }
            if (op && res.data.code === 0) {
                Notification.success({
                    content: "成功",
                });
            }
            if (op && res.data.code !== 0) {
                Notification.error({
                    title: "系统提示",
                    content: res.data.msg,
                });
            }
            resolve(res.data);
        });
    });
};
export default http;