import http from "../utils/http";

export default {
  login: (data) => {
    return http({
      url: "/api/v1.user/login",
      data,
    }).then((res) => res);
  },
  update: (data) => {
    return http({
      url: "/api/v1.user/update",
      data,
      op: false,
    }).then((res) => res);
  },
  updateDept: (data) => {
    return http({
      url: "/api/v1.user/updateDept",
      data,
      op: false,
    }).then((res) => res);
  },
  changePassword: (data) => {
    return http({
      url: "/api/v1.user/changePassword",
      data,
      op: false,
    }).then((res) => res);
  },
  register: (data) => {
    return http({
      url: "/api/v1.user/register",
      data,
    }).then((res) => res);
  },
  uploadUrl: (data) => {
    return http({
      url: "/api/v1/admin/common/upload/url",
      data,
      op: false,
    }).then((res) => res);
  },
};
