<template>
  <div>
    <div class="cu-header">
      <div class="flex align-center" style="width: 100%; height: 100%">
        <div
          class="margin-left-10 text-white text-center"
          style="width: 240px; height: 32px; line-height: 32px"
        >
          <div class="flex align-center">
            <img
              :src="require('/public/logo2.png')"
              style="width: 60px; height: 60px"
            />
            <span
              v-if="user != null"
              class="margin-left-10 text-cut"
              style="font-size: 20px"
            >
              {{
                user.company_name !== "" ? user.company_name : "总平台"
              }}</span
            >
          </div>
          <a-space direction="vertical" size="large"></a-space>
        </div>
        <div
          class="margin-left-10 flex align-center text-white"
          style="width: calc(100% - 260px)"
        >
          <!--          <div v-if="this.user != null">-->
          <!--            <a-select-->
          <!--              v-model="selectDept"-->
          <!--              :disabled="this.user.switch_dept === 0"-->
          <!--              :style="{ width: '200px' }"-->
          <!--              placeholder="请选择"-->
          <!--              @change="DeptChange"-->
          <!--            >-->
          <!--              <a-option-->
          <!--                v-for="(item, index) in departList"-->
          <!--                :label="item.name"-->
          <!--                :value="item.did"-->
          <!--              />-->
          <!--            </a-select>-->
          <!--          </div>-->
          <div class="users-box flex align-center just-end">
            <a-button
              class="margin-right-10"
              status="success"
              type="primary"
              @click.stop="TakeBill"
            >
              <div class="flex align-center">
                <span style="font-size: 16px"> +</span>
                <div>开单</div>
              </div>
            </a-button>
            <a-space class="margin-right-10" direction="vertical" size="large">
              <a-input
                v-model="searchtext"
                :allow-clear="true"
                :style="{ width: '240px' }"
                allow-search
                @input="searchInput"
              />
            </a-space>
            <div class="info-item cursor">
              <a-dropdown
                v-if="user != null"
                trigger="hover"
                @select="handleSelect"
              >
                <div class="flex align-center">
                  <div class="user-avatar">
                    <!--                    <img-->
                    <!--                      v-if="user.avatar !== ''"-->
                    <!--                      :src="user.avatar"-->
                    <!--                      style="width: 32px; height: 32px"-->
                    <!--                    />-->
                    <img
                      :src="require('/public/logo2.png')"
                      style="width: 40px; height: 40px"
                    />
                  </div>
                  <div class="margin-left-10">{{ user.name }}</div>
                  <icon-down class="margin-left-5" />
                </div>
                <template #content>
                  <a-doption value="profile">
                    <template #icon>
                      <icon-user />
                    </template>
                    <template #default> 个人中心</template>
                  </a-doption>
                  <a-doption value="logout">
                    <template #icon>
                      <icon-poweroff />
                    </template>
                    <template #default> 退出登录</template>
                  </a-doption>
                </template>
              </a-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cu-appstore">
      <ul class="cu-ul">
        <li
          v-for="(item, index) in asideData"
          :key="index"
          :class="menuSelect === item.menu_id ? 'active' : ''"
          @click.stop="Aside(item)"
        >
          <div class="flex align-center">
            <div>
              <i
                :class="item.icon"
                class="iconfont padding-lr-3"
                style="display: block"
              ></i>
            </div>
            <div class="margin-left-10">{{ item.title }}</div>
          </div>
        </li>
      </ul>
    </div>
    <div :class="showMenu ? 'show' : ''" class="cu-aside">
      <div class="relative">
        <div
          :class="showMenu ? 'hide' : ''"
          class="icon-box cursor"
          @click.stop="handle"
        >
          <icon-caret-left v-if="!showMenu" />
          <icon-caret-right v-if="showMenu" />
        </div>
        <a-menu
          v-if="!showAside"
          :accordion="true"
          :auto-open-selected="true"
          :default-collapsed="true"
          :selected-keys="[menuActive]"
          :show-collapse-button="false"
          :style="{ width: '100%', height: '100%' }"
          breakpoint="xl"
        >
          <a-sub-menu v-for="(item, index) in menuData" :key="index">
            <template #title>
              <div class="flex align-center">
                <img
                  :src="require('/public/folder.png')"
                  style="width: 20px; height: 20px"
                />
                <span class="text-bold" style="font-size: 14px">{{
                  item.title
                }}</span>
              </div>
            </template>
            <a-menu-item
              v-for="(children, index) in item.children"
              :key="children.name"
              @click.stop="openMenu(children)"
            >
              <icon-file style="width: 14px" />
              <span style="font-size: 14px; margin-left: -10px">{{
                children.title
              }}</span>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </div>
    </div>
    <div id="cu-main" :class="showMenu ? 'show' : ''" class="cu-main">
      <div :class="showMenu ? 'show' : ''" class="select-item-box">
        <a-tabs
          :active-key="menuActive"
          :auto-switch="true"
          :editable="true"
          :hide-content="true"
          type="card-gutter"
          @delete="close"
          @tab-click="TabClick"
        >
          <a-tab-pane
            v-for="(item, index) in menuList"
            :key="item.name"
            :closable="item.fixed === 'not'"
            :title="item.title"
            @click.stop="openMenu(item)"
            >{{ item.title }}
          </a-tab-pane>
        </a-tabs>
        <a-divider :style="{ margin: '10px 0' }" />
      </div>
      <a-scrollbar
        outer-class="scrollbar"
        style="overflow: auto; height: 100%; margin-top: 36px"
      >
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component
              :is="Component"
              v-if="$route.meta.keepAlive"
              :key="$route.name"
            />
          </keep-alive>
          <component
            :is="Component"
            v-if="!$route.meta.keepAlive"
            :key="$route.name"
          />
        </router-view>
      </a-scrollbar>
    </div>
    <!--订单搜索弹窗-->
    <div :class="searchShow === true ? 'show' : ''" class="search-box">
      <div class="search-main">
        <div class="search-content">
          <a-scrollbar style="height: 460px; overflow: auto">
            <div class="searchLi" @click.stop="OrderSearch('全部')">
              <span class="searchI">全部</span>
              <span>{{ searchtext }}</span>
            </div>
            <div class="searchLi" @click.stop="OrderSearch(1)">
              <span class="searchI">运单号</span>
              <span>{{ searchtext }}</span>
            </div>
            <div class="searchLi" @click.stop="OrderSearch(2)">
              <span class="searchI">手工单号</span>
              <span>{{ searchtext }}</span>
            </div>
            <div class="searchLi" @click.stop="OrderSearch(3)">
              <span class="searchI">外部单号</span>
              <span>{{ searchtext }}</span>
            </div>
            <div class="searchLi" @click.stop="OrderSearch(4)">
              <span class="searchI">客户单号</span>
              <span>{{ searchtext }}</span>
            </div>
            <div class="searchLi" @click.stop="OrderSearch(5)">
              <span class="searchI">发货人信息</span>
              <span>{{ searchtext }}</span>
            </div>
            <div class="searchLi" @click.stop="OrderSearch(6)">
              <span class="searchI">收货人信息</span>
              <span>{{ searchtext }}</span>
            </div>
            <div class="searchLi" @click.stop="OrderSearch(7)">
              <span class="searchI">重量</span>
              <span>{{ searchtext }}</span>
            </div>
            <div class="searchLi" @click.stop="OrderSearch(8)">
              <span class="searchI">体积</span>
              <span>{{ searchtext }}</span>
            </div>
            <div class="searchLi" @click.stop="OrderSearch(9)">
              <span class="searchI">件数</span>
              <span>{{ searchtext }}</span>
            </div>
          </a-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import menu from "@/utils/menu";
import { IconDelete, IconDown, IconEdit } from "@arco-design/web-vue/es/icon";
import api from "@/api";
import Order from "@/api/operate/order";
import { Notification } from "@arco-design/web-vue";

export default {
  name: "Layout",
  components: {
    IconDown,
    IconEdit,
    IconDelete,
  },
  data() {
    return {
      selectCompany: 1,
      aside_id: "",
      showMenu: false,
      showAside: false,
      asideData: [],
      menuData: [],
      childrenid: "",
      menuId: "",
      searchtext: "",
      searchShow: false,
      options: [],
      departList: [],
      selectDept: "",
      companyList: [
        {
          id: 1,
          name: "十堰好又快物流有限公司",
        },
      ],
      selectedNavList: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
    };
  },
  computed: {
    Order() {
      return Order;
    },
    user() {
      return this.$store.getters["base/get_user"];
    },
    menuList() {
      return this.$store.getters["base/menu_list"];
    },
    menuActive() {
      return this.$store.getters["base/menu_active"];
    },
    menuSelect() {
      return this.$store.getters["base/menu_select"];
    },
  },
  mounted() {
    // this.GetApplist();
    this.GetLayout();
    this.GetDeptlist();
  },
  methods: {
    GetLayout() {
      this.asideData = this.user.action_group;
      if (this.menuActive === "home") {
        this.$store.dispatch("base/menu_select", this.asideData[0].menu_id);
      }
      this.SetMenuData();
    },
    GetDeptlist() {
      api.organization.dept.list().then((res) => {
        if (res.code === 0) {
          this.departList = res.data;
          this.selectDept = this.user.dept_number;
        }
      });
    },
    DeptChange(value) {
      let _this = this;
      api.user
        .updateDept({
          dept_number: value,
        })
        .then((res) => {
          if (res.code === 0) {
            let newuser = JSON.parse(JSON.stringify(_this.user));
            newuser.dept_id = value;
            _this.$store.dispatch("base/up_user", newuser);
            return Notification.success({
              title: "提示",
              content: "切换部门成功！",
            });
          } else {
            return Notification.error({
              title: "提示",
              content: res.msg,
            });
          }
        });
    },
    SetMenuData() {
      let row = this.asideData.find((item) => item.menu_id === this.menuSelect);
      if (row) {
        this.menuData =
          row.children.length > 0
            ? JSON.parse(JSON.stringify(row.children))
            : [];
      }
    },
    handle() {
      this.showMenu = !this.showMenu;
      if (this.showAside) {
        this.showAside = false;
      } else {
        setTimeout(() => {
          this.showAside = true;
        }, 100);
      }
    },
    Aside(row) {
      this.$store.dispatch("base/menu_select", row.menu_id);
      this.SetMenuData();
      setTimeout(() => {
        if (this.showMenu) {
          this.showMenu = false;
          this.showAside = false;
        }
      }, 300);
    },
    GetApplist() {
      api.organization.app.version().then((res) => {
        if (res.code === 0) {
          this.appRow = res.data;
        }
      });
    },
    searchInput(e) {
      if (e === "") {
        this.searchShow = false;
      } else {
        this.searchShow = true;
      }
    },
    openTopNav(row) {
      if (row.title === "App下载" && this.appRow != null) {
        let a = document.createElement("a");
        a.href = this.appRow.url;
        a.download = "安装包";
        a.click();
      } else {
        if (row.path !== "") {
          this.openPage({
            name: row.name,
            path: row.path,
            title: row.title,
            fixed: "not",
          });
        }
      }
    },
    openMenu(row) {
      this.openPage({
        name: row.name,
        path: row.path,
        title: row.title,
        fixed: "not",
      });
    },
    TabClick(val) {
      let list = this.menuList;
      let row = list.find((item) => item.name === val);
      this.menuId = row.name;
      this.openPage(row);
    },
    openPage(row) {
      menu.open(row);
    },
    close(val) {
      let list = this.menuList;
      let row = list.find((item) => item.name === val);
      this.closePage(row);
    },
    closePage(row) {
      menu.close(row);
    },
    TakeBill() {
      let row = {
        fixed: "not",
        name: "bill",
        path: "/operate/list/bill",
        title: "零担开单",
      };
      this.openPage(row);
    },
    handleSelect(index) {
      if (index === "logout") {
        this.$router.push({
          path: "/login",
        });
      } else if (index === "profile") {
        let row = {
          fixed: "not",
          name: "user",
          path: "/user",
          title: "个人中心",
        };
        this.openPage(row);
      }
    },
    OrderSearch(type) {
      // this.searchShow = false;
      let typeValue = "";
      if (type === "全部") {
        typeValue = "";
      } else {
        typeValue = type;
      }
      api.order.bill
        .orderSearch({
          text: this.searchtext,
          type: typeValue,
        })
        .then((res) => {
          let data = res.data;
          let search = {
            typeValue: typeValue,
            text: this.searchtext,
          };
          this.$store.dispatch("base/up_searchtext", search);
          this.searchtext = "";
          this.searchShow = false;
          if (data.length === 0) {
            return Notification.error({
              title: "提示",
              content: "满足条件的运单不存在！",
            });
          } else {
            let row = {
              fixed: "not",
              name: "order-search",
              path: "/service/search/order-search",
              title: "运单追踪",
            };
            this.openPage(row);
            this.$store.dispatch("base/menu_select", 3);
            this.SetMenuData();
            this.$store.dispatch("base/up_searchOrder", data);
            console.log(data, 111);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
@top: 70px;
@appstore: 70px;
@appstore-bg: #0d1929;
@aside: 200px;
@aside-min: 0px;
.cu-header {
  position: fixed;
  left: 0;
  top: 0;
  height: @top;
  width: 100%;
  background-color: @appstore-bg;
}

.cu-appstore {
  position: fixed;
  left: 0;
  top: @top;
  background-color: @appstore-bg;
  width: @appstore;
  height: calc(100% - @top);
}

.cu-aside {
  position: fixed;
  left: @appstore;
  top: @top;
  width: @aside;
  height: calc(100% - @top);
  background-color: #c1c1c1;
  transition: all 0.2s;
  border-right: 1px solid #f2f3f5;
  z-index: 500;

  .relative {
    width: 100%;
    height: 100%;
  }

  .icon-box {
    width: 15px;
    height: 40px;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f3f5;
    z-index: 500;
    border-radius: 10px 0 0 10px;
    transition: all 0.2s;
  }

  .icon-box:hover {
    background-color: #f3b23e;
  }
}

.cu-aside.show {
  width: @aside-min;
  transition: all 0.2s;
}

.icon-box.hide {
  right: -15px;
  border-radius: 0 10px 10px 0;
  transition: all 0.2s;
}

.cu-main {
  position: fixed;
  left: calc(@appstore + @aside);
  top: @top;
  width: calc(100% - @appstore - @aside);
  height: calc(100% - @top);
  transition: all 0.2s;
  z-index: 100;
  padding: 10px;
  background-color: #ffffff;
}

.scrollbar {
  height: calc(100% - @top);
}

.cu-main.show {
  width: calc(100% - @appstore);
  left: @appstore;
  transition: all 0.2s;
}

.cu-ul {
  padding: 3px;
  margin: 0;

  li {
    margin: 5px 0;
    list-style-type: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    cursor: pointer;
    font-weight: bold;
    line-height: 50px;
    height: 50px;
  }

  li.active,
  li:hover {
    background-color: #165dff;
    border-radius: 5px;
    color: #ffffff;
  }
}

.icon:hover {
  background-color: #f3b23e;
}

.users-box {
  width: 100%;
  height: @top;
  line-height: @top;
  //background-color: #f3b23e;
}

.select-item-box {
  width: calc(100% - @appstore - @aside);
  position: fixed;
  top: @top;
  z-index: 999;
  background-color: white;
}

.select-item-box.show {
  width: calc(100% - @appstore);
}

.user-avatar {
  height: 40px;
  width: 40px;
  line-height: @top;
  border-radius: 40px;
  background-color: #ffffff;

  img {
    width: 100%;
    height: 100%;
    border-radius: 17px;
  }
}

.info-item {
  height: 100%;

  padding-left: 10px;
  padding-right: 10px;
}

.info-item:hover {
  background-color: #f3b23e;
  color: #000000;
}

.arco-menu-inline-header.arco-menu-selected {
  background: #333333;
}

.search-box {
  position: fixed;
  background-color: rgba(29, 33, 41, 0.6);
  width: 100%;
  height: calc(100% - 55px);
  top: 55px;
  z-index: 99999;
  display: none;

  .search-main {
    position: relative;
    width: 100%;
    height: 100%;

    .search-content {
      position: absolute;
      right: 126px;
      width: 300px;
      border-radius: 3px;

      .searchLi {
        height: 24px;
        padding: 10px;
        line-height: 24px;
        color: white;
        background-color: #2c394f;

        .searchI {
          margin-right: 20px;
          font-weight: bold;
        }
      }

      .searchLi.active,
      .searchLi:hover {
        background-color: #4a55a5;
      }
    }
  }
}

.search-box.show {
  display: block;
}
</style>
