import http from "@/utils/http";

export default {
  withdraw: {
    list: (data) => {
      return http({
        url: "/api/v1.money/remitList",
        data,
        op: false,
      }).then((res) => res);
    },
    remit: (data) => {
      return http({
        url: "/api/v1.money/remit",
        data,
      }).then((res) => res);
    },
  },
};
