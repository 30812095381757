import http from "@/utils/http";

export default {
  businesslist: {
    allList: (data) => {
      return http({
        url: "/api/v1/admin/company/allList",
        data,
        op: false,
      }).then((res) => res);
    },
    list: (data) => {
      return http({
        url: "/api/v1/admin/company/list",
        data,
        op: false,
      }).then((res) => res);
    },
    cityCompany: (data) => {
      return http({
        url: "/api/v1/admin/company/cityCompany",
        data,
        op: false,
      }).then((res) => res);
    },
    add: (data) => {
      return http({
        url: "/api/v1/admin/company/add",
        data,
        op: false,
      }).then((res) => res);
    },
    audit: (data) => {
      return http({
        url: "/api/v1/admin/company/audit",
        data,
      }).then((res) => res);
    },
    //绑定
    stopBind: (data) => {
      return http({
        url: "/api/v1/admin/company/stopBind",
        data,
      }).then((res) => res);
    },
    bindHouse: (data) => {
      return http({
        url: "/api/v1/admin/company/bindHouse",
        data,
      }).then((res) => res);
    },
    stopCompanyBindList: (data) => {
      return http({
        url: "/api/v1/admin/company/stopCompanyBindList",
        data,
        op: false,
      }).then((res) => res);
    },
    geoBindCompany: (data) => {
      return http({
        url: "/api/v1/admin/company/geoBindCompany",
        data,
        op: false,
      }).then((res) => res);
    },
    getByCalcList: (data) => {
      return http({
        url: "/api/v1/admin/company/getByCalcList",
        data,
        op: false,
      }).then((res) => res);
    },
  },
  contract: {
    list: (data) => {
      return http({
        url: "/api/v1/admin/resource/list",
        data,
        op: false,
      }).then((res) => res);
    },
    create: (data) => {
      return http({
        url: "/api/v1/admin/resource/create",
        data,
      }).then((res) => res);
    },
    edit: (data) => {
      return http({
        url: "/api/v1/admin/resource/edit",
        data,
      }).then((res) => res);
    },
    delete: (data) => {
      return http({
        url: "/api/v1/admin/resource/delete",
        data,
      }).then((res) => res);
    },
  },
};
