<template>
  <div>
    <!--    <div class="margin-tb-10">-->
    <!--      <a-button >新增</a-button>-->
    <!--      <a-button class="margin-left-20">编辑</a-button>-->
    <!--      <a-button class="margin-left-20">删除</a-button>-->
    <!--    </div>-->
    <a-table
      ref="table"
      v-model:expandedKeys="option.expandedKeys"
      :bordered="{ cell: true }"
      :data="option.tableData"
      :hoverable="true"
      :loading="option.loading"
      :pagination="option.pagination"
      :row-key="option.rowKey"
      :row-selection="option.rowSelection"
      :show-header="option.showHeader"
      :stripe="true"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      column-resizable
      hide-expand-button-on-empty
      page-position="bl"
      @select="select"
      @page-change="pageChange"
      @page-size-change="sizeChange"
    >
      <template #columns>
        <a-table-column
          v-for="(item, index) in option.tableHead"
          :key="index"
          :data-index="item.dataIndex"
          :title="item.title"
        >
          <template #cell="{ record, rowIndex }">
            <div v-if="rowIndex === 0">
              <a-input size="small"></a-input>
            </div>
            <div v-else>{{ record }}</div>
          </template>
        </a-table-column>
      </template>
      <template v-for="item in renderArr" v-slot:[item]="scope">
        <slot :name="item" :scope="scope"></slot>
      </template>
    </a-table>
  </div>
</template>
<script setup>
import { useSlots } from "vue";

const slots = useSlots();
// 插槽的实例
const renderArr = Object.keys(slots);
// 渲染的数据
</script>
<script>
export default {
  data() {
    return {
      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  props: {
    option: {
      type: Object,
      default: () => {
        return {
          tableData: {
            type: Array,
            default() {
              return [];
            },
            required: true,
          }, //数据
          tableHead: {
            type: Array,
            default() {
              return [];
            },
            required: true,
          },
          rowSelection: {
            type: "checkbox",
            showCheckedAll: false,
            selectedRowKeys: [],
          },
          columns: false,
          expandedKeys: [],
          rowKey: "",
          pagination: {
            page: 1,
            pageSize: 10,
            total: 0,
            size: "small",
          },
          sort: {
            prop: "",
            order: "descending",
          }, //排序规则
        };
      },
    },
  },
  watch() {
    console.log(this.props, 111);
  },
  methods: {
    pageChange(page) {
      this.$emit("pageChange", page);
    },
    sizeChange(szie) {
      this.$emit("pageSize", szie);
    },
    select(rowKeys) {
      this.option.rowSelection.selectedRowKeys = rowKeys;
    },
    EditRow(row) {
      this.$emit("EditRow", row);
    },
    RemoveRow(row) {
      this.$emit("RemoveRow", row);
    },
  },
};
</script>
<style>
.custom-filter {
  padding: 20px;
  background: var(--color-bg-5);
  border: 1px solid var(--color-neutral-3);
  border-radius: var(--border-radius-medium);
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
}

.custom-filter-footer {
  display: flex;
  justify-content: space-between;
}
</style>
