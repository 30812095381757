import store from "../store/index.js";
import router from "../router/index.js";

export default {
  open: ({ name, path, title, row, fixed = "not" }) => {
    let list = JSON.parse(JSON.stringify(store.getters["base/menu_list"]));
    let index = list.findIndex((item) => item.name === name);
    if (index === -1 && name !== "home") {
      list.push({
        name: name,
        path: path,
        title: title,
        fixed: fixed,
      });
      store.dispatch("base/menu_list", list).then((r) => r);
    }
    store.dispatch("base/menu_active", name).then((r) => r);
    router.push({ path: path }).then((r) => r);
  },
  close: ({ name, fixed = "not" }) => {
    let list = JSON.parse(JSON.stringify(store.getters["base/menu_list"]));
    let active = store.getters["base/menu_active"];
    let length = list.length - 1;
    let index = list.findIndex((item) => item.name === name);
    //选中与关闭的不是同一个
    if (list[index].name !== active) {
      list.splice(index, 1);
      store.dispatch("base/menu_list", list).then((r) => r);
    } else {
      //选中与关闭是同一个
      if (index !== -1 && fixed === "not") {
        let url = "";
        let active = "";
        //关闭的最后一个
        if (index === length) {
          url = list[length - 1].path;
          active = list[length - 1].name;
        } else {
          url = list[index + 1].path;
          active = list[index + 1].name;
        }
        list.splice(index, 1);
        store.dispatch("base/menu_active", active).then((r) => r);
        store.dispatch("base/menu_list", list).then((r) => r);
        router.push({ path: url }).then((r) => r);
      }
    }
  },
};
