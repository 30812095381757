import http from "@/utils/http";

export default {
  wallet: {
    getMyMoney: (data) => {
      return http({
        url: "/api/v1.money/getMyMoney",
        data,
        op: false,
      }).then((res) => res);
    },
    getMyCompanyOverMoney: (data) => {
      return http({
        url: "/api/v1.money/getMyCompanyOverMoney",
        data,
        op: false,
      }).then((res) => res);
    },
    withdraw: (data) => {
      return http({
        url: "/api/v1.money/withdraw",
        data,
      }).then((res) => res);
    },
    getMyMoneyDetail: (data) => {
      return http({
        url: "/api/v1.money/getMyMoneyDetail",
        data,
        op: false,
      }).then((res) => res);
    },
    getMyMoneyPoCompany: (data) => {
      return http({
        url: "/api/v1.money/getMyMoneyPoCompany",
        data,
        op: false,
      }).then((res) => res);
    },
    getMyMoneySoCompany: (data) => {
      return http({
        url: "/api/v1.money/getMyMoneySoCompany",
        data,
        op: false,
      }).then((res) => res);
    },
    getCompanyMoneyDetail: (data) => {
      return http({
        url: "/api/v1.money/getCompanyMoneyDetail",
        data,
        op: false,
      }).then((res) => res);
    },
    examine: (data) => {
      return http({
        url: "/api/v1.money/examine",
        data,
      }).then((res) => res);
    },
    feedback: (data) => {
      return http({
        url: "/api/v1.money/feedback",
        data,
      }).then((res) => res);
    },
    feedbackPoList: (data) => {
      return http({
        url: "/api/v1.money/feedbackPoList",
        data,
        op: false,
      }).then((res) => res);
    },
    feedbackCheck: (data) => {
      return http({
        url: "/api/v1.money/feedbackCheck",
        data,
      }).then((res) => res);
    },
    export: (data) => {
      return http({
        url: "/api/v1.money/export",
        data,
      }).then((res) => res);
    },
  },
  account: {
    companyList: (data) => {
      return http({
        url: "/api/v1.money/companyList",
        data,
        op: false,
      }).then((res) => res);
    },
    recharge: (data) => {
      return http({
        url: "/api/v1.money/recharge",
        data,
      }).then((res) => res);
    },
  },
  audit: {
    record: (data) => {
      return http({
        url: "/api/v1.money/record",
        data,
      }).then((res) => res);
    },
    typeList: (data) => {
      return http({
        url: "/api/v1.money/typeList",
        data,
        op: false,
      }).then((res) => res);
    },
    checkList: (data) => {
      return http({
        url: "/api/v1.money/checkList",
        data,
        op: false,
      }).then((res) => res);
    },
    getWithdrawList: (data) => {
      return http({
        url: "/api/v1.money/getWithdrawList",
        data,
        op: false,
      }).then((res) => res);
    },
    checkWithdraw: (data) => {
      return http({
        url: "/api/v1.money/checkWithdraw",
        data,
      }).then((res) => res);
    },
  },
  bank: {
    list: (data) => {
      return http({
        url: "/api/v1.bank/list",
        data,
        op: false,
      }).then((res) => res);
    },
    create: (data) => {
      return http({
        url: "/api/v1.bank/create",
        data,
      }).then((res) => res);
    },
    update: (data) => {
      return http({
        url: "/api/v1.bank/update",
        data,
      }).then((res) => res);
    },
    del: (data) => {
      return http({
        url: "/api/v1.bank/delete",
        data,
      }).then((res) => res);
    },
  },
};
