<template>
  <router-view />
</template>
<script>
export default {
  name: "App",
  created() {
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", async () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
};
</script>
<style lang="less">
@import "assets/laout.less";
@import "@/assets/iconfont/iconfont.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.arco-input-wrapper.arco-input-disabled {
  color: black !important;
}

.select-item-box {
  .arco-tabs-nav::before {
    background-color: transparent !important;
  }

  .arco-tabs-nav-type-card-gutter .arco-tabs-tab-active,
  .arco-tabs-nav-type-card-gutter .arco-tabs-tab-active:hover {
    background-color: #4a55a5 !important;
    color: #ffffff;
  }

  .arco-tabs-nav-type-card-gutter .arco-tabs-tab {
    background-color: #eeeeee;
  }
}

.arco-select-view-value {
  color: black;
}

.arco-form-item-wrapper-col,
.arco-form-item-content {
  min-height: 28px !important;
}

.arco-form-item-label-col {
  line-height: 28px !important;
}

.vgt-responsive {
  overflow-y: scroll;
  overflow-x: scroll;
  cursor: pointer;
}

.vgt-responsive::-webkit-scrollbar {
  /* 隐藏默认的滚动条 */
  -webkit-appearance: none;
}

.vgt-responsive::-webkit-scrollbar:vertical {
  /* 设置垂直滚动条宽度 */
  width: 2px;
}

.vgt-responsive::-webkit-scrollbar:horizontal {
  /* 设置水平滚动条厚度 */
  height: 10px;
}

.vgt-responsive::-webkit-scrollbar-thumb {
  /* 滚动条的其他样式定制，注意，这个一定也要定制，否则就是一个透明的滚动条 */
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.4);
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}
</style>
