<template>
  <div style="width: 100%">
    <a-spin
      :loading="option.tableLoading"
      style="width: calc(100% - 20px)"
      tip="正在获取，请稍后"
    >
      <vue-good-table
        ref="myTable"
        :columns="option.tableHead"
        :group-options="option.group"
        :max-height="option.maxHeight"
        :row-style-class="rowStyleClassFn"
        :rows="option.tableData"
        :select-options="option.select"
        class="padding-10 solid"
        styleClass="vgt-table bordered"
        theme="polar-bear"
        @on-selected-rows-change="SelectionTable"
        v-on:row-click="TakeRow"
      >
        <!--  theme="black-rhino"     -->
        <template #table-header-row="props">
          <slot :name="props.column.solt" :scope="props.row"></slot>
        </template>
        <template #table-row="props">
          <slot :name="props.column.solt" :scope="props.row"></slot>
        </template>
        <template #emptystate>
          <a-empty />
        </template>
        <template #table-actions-bottom>
          <div v-if="option.pagination" class="padding-10">
            <a-pagination
              :current="option.pagination.page"
              :page-size="option.pagination.row"
              :page-size-options="[10, 20, 50, 100, 500]"
              :total="option.pagination.total"
              show-page-size
              show-total
              size="small"
              @change="pageChange"
              @page-size-change="sizeChange"
            />
          </div>
        </template>
      </vue-good-table>
    </a-spin>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedRowKeys: [],
      selectedRows: [],
      pageIndex: "",
    };
  },
  props: {
    option: {
      type: Object,
      default: () => {
        return {
          tableData: {
            type: Array,
            default() {
              return [];
            },
            required: true,
          }, //数据
          tableHead: {
            type: Array,
            default() {
              return [];
            },
            required: true,
          },
          pagination: {
            page: 1,
            row: 10,
            total: 0,
          },
          group: {
            enabled: true,
            rowKey: option.rowKey,
            collapsable: true,
          },
          tableLoading: false,
          columns: false,
          select: { enabled: true },
          expandedKeys: [],
          rowKey: "",
          sort: {
            prop: "",
            order: "descending",
          }, //排序规则
        };
      },
    },
  },
  methods: {
    pageChange(page) {
      this.$emit("pageChange", page);
    },
    sizeChange(szie) {
      this.$emit("sizeChange", szie);
    },
    SelectChange(params) {
      this.pageIndex = params.pageIndex;
    },
    EditRow(row) {
      this.$emit("EditRow", row);
    },
    RemoveRow(row) {
      this.$emit("RemoveRow", row);
    },
    rowStyleClassFn(row) {},
    SelectionTable() {},
    selection() {
      return this.$refs.myTable.selectedRows;
    },
    TakeRow(params) {
      console.log(params);
    },
  },
};
</script>
<style lang="less">
.vgt-responsive {
  overflow-y: scroll;
  overflow-x: scroll;
  cursor: pointer;
}

.vgt-responsive::-webkit-scrollbar {
  /* 隐藏默认的滚动条 */
  -webkit-appearance: none;
}

.vgt-responsive::-webkit-scrollbar:vertical {
  /* 设置垂直滚动条宽度 */
  width: 2px;
}

.vgt-responsive::-webkit-scrollbar:horizontal {
  /* 设置水平滚动条厚度 */
  height: 10px;
}

.vgt-responsive::-webkit-scrollbar-thumb {
  /* 滚动条的其他样式定制，注意，这个一定也要定制，否则就是一个透明的滚动条 */
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.4);
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}

.vgt-table.polar-bear {
  border-collapse: collapse !important;
}

.vgt-table.polar-bear.bordered td {
  font-size: 14px !important;
  padding: 10px;
  vertical-align: middle;
}

.vgt-table.polar-bear thead th {
  background: rgb(242, 243, 245);
  font-size: 14px !important;
  text-align: center;
}

.vgt-table.polar-bear thead th .vgt-input,
.vgt-table.polar-bear thead th .vgt-select {
  box-shadow: initial;
  height: 100%;
}

.text-center {
  text-align: center;
}

.vgt-table.polar-bear.bordered td {
  border: 1px solid #ecedef;
}

.vgt-table.polar-bear.bordered th {
  border: 1px solid #ecedef;
}

.vgt-table th.vgt-row-header .triangle {
  //padding: 5px;
}

.vgt-table {
  height: 100%;
  overflow: scroll;
}

.vgt-wrap__actions-footer {
  border: none;
}

.vgt-table.polar-bear thead th .vgt-input,
.vgt-table.polar-bear thead th .vgt-select {
  border: 1px solid #c9c9c9;
}
</style>
