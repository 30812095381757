import http from "@/utils/http";

export default {
  client: {
    list: (data) => {
      return http({
        url: "/api/v2.ProjectCustom/list",
        data,
        op: false,
      }).then((res) => res);
    },
    create: (data) => {
      return http({
        url: "/api/v2.ProjectCustom/create",
        data,
      }).then((res) => res);
    },
    update: (data) => {
      return http({
        url: "/api/v2.ProjectCustom/update",
        data,
      }).then((res) => res);
    },
    del: (data) => {
      return http({
        url: "/api/v2.ProjectCustom/delete",
        data,
      }).then((res) => res);
    },
  },
  project: {
    list: (data) => {
      return http({
        url: "/api/v2.Project/list",
        data,
        op: false,
      }).then((res) => res);
    },
    create: (data) => {
      return http({
        url: "/api/v2.Project/create",
        data,
      }).then((res) => res);
    },
    update: (data) => {
      return http({
        url: "/api/v2.Project/update",
        data,
      }).then((res) => res);
    },
    del: (data) => {
      return http({
        url: "/api/v2.ProjectCustom/delete",
        data,
      }).then((res) => res);
    },
  },
  bill: {
    create: (data) => {
      return http({
        url: "/api/v2.ProjectOrder/create",
        data,
        op: false,
      }).then((res) => res);
    },
    list: (data) => {
      return http({
        url: "/api/v2.ProjectOrder/list",
        data,
        op: false,
      }).then((res) => res);
    },
    sign: (data) => {
      return http({
        url: "/api/v2.ProjectOrder/sign",
        data,
      }).then((res) => res);
    },
    backSign: (data) => {
      return http({
        url: "/api/v2.ProjectOrder/backSign",
        data,
      }).then((res) => res);
    },
    billList: (data) => {
      return http({
        url: "/api/v2.ProjectOrder/billList",
        data,
        op: false,
      }).then((res) => res);
    },
    setBill: (data) => {
      return http({
        url: "/api/v2.ProjectOrder/setBill",
        data,
      }).then((res) => res);
    },
    isBillList: (data) => {
      return http({
        url: "/api/v2.ProjectOrder/isBillList",
        data,
        op: false,
      }).then((res) => res);
    },
    setInvoice: (data) => {
      return http({
        url: "/api/v2.ProjectOrder/setInvoice",
        data,
      }).then((res) => res);
    },
    setSoMoneyIsPay: (data) => {
      return http({
        url: "/api/v2.ProjectOrder/setSoMoneyIsPay",
        data,
      }).then((res) => res);
    },
    setPoMoneyIsPay: (data) => {
      return http({
        url: "/api/v2.ProjectOrder/setPoMoneyIsPay",
        data,
      }).then((res) => res);
    },
  },
};
