import http from "@/utils/http";

export default {
  //角色接口
  company: {
    add: (data) => {
      return http({
        url: "/api/v1.company/create",
        data,
      }).then((res) => res);
    },
    update: (data) => {
      return http({
        url: "/api/v1.company/update",
        data,
      }).then((res) => res);
    },
    del: (data) => {
      return http({
        url: "/api/v1.company/delete",
        data,
      }).then((res) => res);
    },
    list: (data) => {
      return http({
        url: "/api/v1.company/list",
        data,
        op: false,
      }).then((res) => res);
    },
    uploadLicense: (data) => {
      return http({
        url: "/api/v1.company/uploadLicense",
        data,
        op: false,
      }).then((res) => res);
    },
    listByCompany: (data) => {
      return http({
        url: "/api/v1.dept/listByCompany",
        data,
        op: false,
      }).then((res) => res);
    },
    children: (data) => {
      return http({
        url: "/api/v1.company/children",
        data,
        op: false,
      }).then((res) => res);
    },
    detail: (data) => {
      return http({
        url: "/api/v1.company/detail",
        data,
        op: false,
      }).then((res) => res);
    },
    getAcceptLineCompany: (data) => {
      return http({
        url: "/api/v1.company/getAcceptLineCompany",
        data,
        op: false,
      }).then((res) => res);
    },
  },
  //部门接口
};
