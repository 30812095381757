import http from "@/utils/http";

export default {
  driver: {
    list: (data) => {
      return http({
        url: "/api/v1.car/list",
        data,
        op: false,
      }).then((res) => res);
    },
    create: (data) => {
      return http({
        url: "/api/v1.car/create",
        data,
      }).then((res) => res);
    },
    update: (data) => {
      return http({
        url: "/api/v1.car/update",
        data,
      }).then((res) => res);
    },
    del: (data) => {
      return http({
        url: "/api/v1.car/delete",
        data,
      }).then((res) => res);
    },
    findAll: (data) => {
      return http({
        url: "/api/v1.car/findAll",
        data,
        op: false,
      }).then((res) => res);
    },
  },
};
