import http from "@/utils/http";

export default {
  order: {
    list: (data) => {
      return http({
        url: "/api/v1.order/list",
        data,
        op: false,
      }).then((res) => res);
    },
    controlList: (data) => {
      return http({
        url: "/api/v1.order/controlList",
        data,
        op: false,
      }).then((res) => res);
    },
    overControl: (data) => {
      return http({
        url: "/api/v1.order/overControl",
        data,
      }).then((res) => res);
    },
  },
};
