import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ArcoVueIcon from "@arco-design/web-vue/es/icon";
import ArcoVue from "@arco-design/web-vue";
import "@arco-themes/vue-pc1-0/index.less";
import VueGoodTablePlugin from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";
import XTable from "./components/table/XTable.vue";
import FTable from "./components/table/FTable.vue";
import XDrawer from "./components/drawer/XDrawer.vue";
import XModal from "./components/modal/XModal.vue";
import "element-plus/dist/index.css";
import ElementPlus from "element-plus";

const app = createApp(App);
app.component("XTable", XTable);
app.component("FTable", FTable);
app.component("XDrawer", XDrawer);
app.component("XModal", XModal);
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(VueGoodTablePlugin);
app.use(ArcoVue);
app.use(ArcoVueIcon);
app.mount("#app");
