import http from "@/utils/http";

export default {
  arteryPrice: {
    list: (data) => {
      return http({
        url: "/api/v1.price/list",
        data,
        op: false,
      }).then((res) => res);
    },
    add: (data) => {
      return http({
        url: "/api/v1.price/create",
        data,
      }).then((res) => res);
    },
    update: (data) => {
      return http({
        url: "/api/v1.price/update",
        data,
      }).then((res) => res);
    },
    del: (data) => {
      return http({
        url: "/api/v1.price/delete",
        data,
      }).then((res) => res);
    },
    detailCreate: (data) => {
      return http({
        url: "/api/v1.price/detailCreate ",
        data,
      }).then((res) => res);
    },
    detailList: (data) => {
      return http({
        url: "/api/v1.price/detailList ",
        data,
        op: false,
      }).then((res) => res);
    },
    LinkBind: (data) => {
      return http({
        url: "/api/v1.price/coefficientBind",
        data,
      }).then((res) => res);
    },
    LinkList: (data) => {
      return http({
        url: "/api/v1.price/coefficientBindList",
        data,
        op: false,
      }).then((res) => res);
    },
    linkRemoveBind: (data) => {
      return http({
        url: "/api/v1.price/coefficientNotBind",
        data,
      }).then((res) => res);
    },
  },
  coefficient: {
    list: (data) => {
      return http({
        url: "/api/v1.Coefficient/list",
        data,
        op: false,
      }).then((res) => res);
    },
    add: (data) => {
      return http({
        url: "/api/v1.Coefficient/create",
        data,
      }).then((res) => res);
    },
    update: (data) => {
      return http({
        url: "/api/v1.Coefficient/update ",
        data,
      }).then((res) => res);
    },
    del: (data) => {
      return http({
        url: "/api/v1.Coefficient/delete",
        data,
      }).then((res) => res);
    },
  },
  deliproduct: {
    list: (data) => {
      return http({
        url: "/api/v1.delivery/list",
        data,
        op: false,
      }).then((res) => res);
    },
    add: (data) => {
      return http({
        url: "/api/v1.delivery/create",
        data,
      }).then((res) => res);
    },
    edit: (data) => {
      return http({
        url: "/api/v1.delivery/update",
        data,
      }).then((res) => res);
    },
    del: (data) => {
      return http({
        url: "/api/v1.delivery/delete",
        data,
      }).then((res) => res);
    },
    detailCreate: (data) => {
      return http({
        url: "/api/v1.delivery/detailCreate",
        data,
      }).then((res) => res);
    },
    detailList: (data) => {
      return http({
        url: "/api/v1.delivery/detailList",
        data,
        op: false,
      }).then((res) => res);
    },
  },
  PriceCard: {
    list: (data) => {
      return http({
        url: "/api/v1.PriceCard/list",
        data,
        op: false,
      }).then((res) => res);
    },
    create: (data) => {
      return http({
        url: "/api/v1.PriceCard/create",
        data,
      }).then((res) => res);
    },
    update: (data) => {
      return http({
        url: "/api/v1.PriceCard/update",
        data,
      }).then((res) => res);
    },
  },
  rate: {
    list: (data) => {
      return http({
        url: "/api/v1.basic/dictionaryList",
        data,
        op: false,
      }).then((res) => res);
    },
    create: (data) => {
      return http({
        url: "/api/v1.PriceRate/create",
        data,
      }).then((res) => res);
    },
    update: (data) => {
      return http({
        url: "/api/v1.PriceRate/update",
        data,
      }).then((res) => res);
    },
    ratelist: (data) => {
      return http({
        url: "/api/v1.PriceRate/list",
        data,
        op: false,
      }).then((res) => res);
    },
  },
  geofence: {
    list: (data) => {
      return http({
        url: "/api/v1.geofence/list",
        data,
        op: false,
      }).then((res) => res);
    },
    create: (data) => {
      return http({
        url: "/api/v1.geofence/create",
        data,
      }).then((res) => res);
    },
    update: (data) => {
      return http({
        url: "/api/v1.geofence/update",
        data,
      }).then((res) => res);
    },
    del: (data) => {
      return http({
        url: "/api/v1.geofence/delete",
        data,
      }).then((res) => res);
    },
    priceCreate: (data) => {
      return http({
        url: "/api/v1.geofence/priceCreate",
        data,
      }).then((res) => res);
    },
    priceList: (data) => {
      return http({
        url: "/api/v1.geofence/priceList",
        data,
        op: false,
      }).then((res) => res);
    },
  },
};
