const state = {
  user: null,
  tagList: null, //副导航
  menuActive: "home", //导航选中,
  menuList: [
    {
      path: "/home",
      name: "home",
      fixed: "yes",
      title: "首页",
    },
  ],
  menuSelect: 0, //一级菜单选中
  searchOrder: [],
  searchtext: null,
};
const mutations = {
  set_out(state) {
    state.user = null;
    state.tagList = null;
    state.menuActive = "home";
    state.menuList = [
      {
        path: "/home",
        name: "home",
        fixed: "yes",
        title: "首页",
      },
    ];
  },
  set_user(state, data) {
    state.user = data;
  },
  set_tag(state, data) {
    state.tagList = data;
  },
  set_active(state, data) {
    state.menuActive = data;
  },
  menu_active(state, data) {
    state.menuActive = data;
  },
  menu_list(state, data) {
    state.menuList = data;
  },
  menu_select(state, data) {
    state.menuSelect = data;
  },
  set_searchOrder(state, data) {
    state.searchOrder = data;
  },
  set_searchtext(state, data) {
    state.searchtext = data;
  },
};
const actions = {
  up_user({ commit }, data) {
    commit("set_user", data);
  },
  logout({ commit }) {
    commit("set_out");
  },
  up_tag({ commit }, data) {
    commit("set_tag", data);
  },
  up_active({ commit }, data) {
    commit("set_active", data);
  },
  menu_active({ commit }, data) {
    commit("menu_active", data);
  },
  menu_list({ commit }, data) {
    commit("menu_list", data);
  },
  menu_select({ commit }, data) {
    commit("menu_select", data);
  },
  up_searchOrder({ commit }, data) {
    commit("set_searchOrder", data);
  },
  up_searchtext({ commit }, data) {
    commit("set_searchtext", data);
  },
};
const getters = {
  get_user(state) {
    return state.user;
  },
  get_tag(state) {
    return state.tagList ?? [];
  },
  get_active(state) {
    return state.menuActive;
  },
  menu_active(state) {
    return state.menuActive;
  },
  menu_list(state) {
    return state.menuList;
  },
  menu_select(state) {
    return state.menuSelect;
  },
  get_searchOrder(state) {
    return state.searchOrder;
  },
  get_searchtext(state) {
    return state.searchtext;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
