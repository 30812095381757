import http from "@/utils/http";

export default {
  deliveryList: (data) => {
    return http({
      url: "/api/v1.BackOrder/deliveryList",
      data,
      op: false,
    }).then((res) => res);
  },
  inputList: (data) => {
    return http({
      url: "/api/v1.BackOrder/inputList",
      data,
      op: false,
    }).then((res) => res);
  },
  sign: (data) => {
    return http({
      url: "/api/v1.BackOrder/sign",
      data,
    }).then((res) => res);
  },
  send: (data) => {
    return http({
      url: "/api/v1.BackOrder/send",
      data,
    }).then((res) => res);
  },
  uploadImage: (data) => {
    return http({
      url: "/api/v1.BackOrder/uploadImage",
      data,
    }).then((res) => res);
  },
  imageSign: (data) => {
    return http({
      url: "/api/v1.BackOrder/imageSign",
      data,
    }).then((res) => res);
  },
  backSign: (data) => {
    return http({
      url: "/api/v1.BackOrder/backSign",
      data,
    }).then((res) => res);
  },
  backCompany: (data) => {
    return http({
      url: "/api/v1.BackOrder/backCompany",
      data,
    }).then((res) => res);
  },
};
